#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden;
  font-family: "Indie Flower", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  background-color: #e9e9d1;
  margin: 0;
}

code {
  font-family: "Indie Flower", cursive;
}
